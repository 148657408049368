import useLocalStorage from "hooks/useLocalStorage";
import { createContext } from "react";
import { THEMES } from "utils/constants";
const initialSettings = {
  direction: "ltr",
  theme: THEMES.DARK,
  activeLayout: "layout1",
  responsiveFontSizes: true,
  token: "",
  tokenSubscribed: []
};
export const SettingsContext = createContext({
  settings: initialSettings,
  saveSettings: (arg) => { },
});
const SettingsProvider = ({ children }) => {
  const { data: settings, storeData: setStoreSettings } = useLocalStorage(
    "settings",
    initialSettings,
  );

  const saveSettings = (updateSettings) => {
    setStoreSettings(updateSettings);
  };

  return (
    <SettingsContext.Provider
      value={{
        settings: settings,
        saveSettings,
      }}>
      {children}
    </SettingsContext.Provider>
  );
};
export default SettingsProvider;
